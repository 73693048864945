@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";

@keyframes animbuild1 {
    0% { opacity: 0; left: -10%; top: 80%;}
    18% { opacity: 0; left: -10%; top: 80%;}
    35% { opacity: 1; left: -10%; top: 80%;}
    60% { opacity: 0; left: -10%; top: 80%;}
    61% { opacity: 0; left: 80%; top: 10%;}
    85% { opacity: 1; left: 80%; top: 10%;}
    100% { opacity: 0; left: 80%; top: 10%;}
}

@keyframes animbuild2 {
    0% { opacity: 0; left: 0%; top: 20%;}
    25% { opacity: 1; left: 0%; top: 20%;}
    50% { opacity: 0; left: 0%; top: 20%;}
    51% { opacity: 0; left: 70%; top: 60%;}
    75% { opacity: 1; left: 70%; top: 60%;}
    100% { opacity: 0; left: 70%; top: 60%;}
}

@keyframes animbuild3 {
    0% { opacity: 0; left: 30%; top: 0%;}
    18% { opacity: 0; left: 30%; top: 0%;}
    40% { opacity: 1; left: 30%; top: 0%;}
    60% { opacity: 0; left: 30%; top: 0%;}
    61% { opacity: 0; left: 50%; top: 90%;}
    85% { opacity: 1; left: 50%; top: 90%;}
    100% { opacity: 0; left: 50%; top: 90%;}
}

@keyframes animbuild4 {
    0% { opacity: 0; left: 75%; top: 40%;}
    25% { opacity: 1; left: 75%; top: 40%;}
    50% { opacity: 0; left: 75%; top: 40%;}
    51% { opacity: 0; left: 10%; top: 20%;}
    75% { opacity: 0; left: 10%; top: 20%;}
    100% { opacity: 0; left: 10%; top: 20%;}
}

@keyframes animbuild5 {
    0% { opacity: 0; left: 0%; top: 20%;}
    18% { opacity: 0; left: 0%; top: 20%;}
    35% { opacity: 0; left: 0%; top: 20%;}
    60% { opacity: 0; left: 0%; top: 20%;}
    61% { opacity: 0; left: 0%; top: 20%;}
    85% { opacity: 1; left: 0%; top: 20%;}
    100% { opacity: 0; left: 0%; top: 20%;}
}

@keyframes animbuild6 {
    0% { opacity: 1; left: 10%; top: 80%;}
    25% { opacity: 0; left: 10%; top: 80%;}
    26% { opacity: 0; left: 80%; top: 0%;}
    50% { opacity: 1; left: 80%; top: 0%;}
    75% { opacity: 0; left: 80%; top: 0%;}
    76% { opacity: 0; left: 10%; top: 80%;}
    100% { opacity: 1; left: 10%; top: 80%;}
}


.landing {

  &.home{
    height: 100vh;
  }

  @include sfumatura(1);

  .intro{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../images/pattern.png);
    height: calc(100vh - 140px);
    overflow: hidden;
    width: calc(100% - 40px);
    margin: 0px 20px;

    @include breakpoint(sm) {
      height: calc(100vh - 160px);
    }

    @include breakpoint(md) {
      height: calc(100vh - 160px);
      width: calc(100% - 160px);
      margin: 0px 80px;
    }

    h2{
      @include font-format('intro');
      color: $white;
      text-align: center;
    }

    .buildings{
      opacity: .4;

      > *{
        position: absolute;
        transform: scale(.6);
      }

      @include breakpoint(md) {

        > *{
          transform: scale(.7);
        }
      }

       @include breakpoint(lg) {
         opacity: .6;

         > *{
           transform: scale(.8);
         }
       }

       @include breakpoint(xl) {
         > *{
           transform: scale(1);
         }
       }

       @include breakpoint(xxl) {
         opacity: .8;

         > *{
           transform: scale(1.3);
         }
       }

      .build1{ animation: animbuild1 7s 0s infinite;}
      .build2{ animation: animbuild2 7s 0s infinite;}
      .build3{ animation: animbuild3 7s 0s infinite;}
      .build4{ animation: animbuild4 7s 0s infinite;}
      .build5{ animation: animbuild5 7s 0s infinite;}
      .build6{ animation: animbuild6 7s 0s infinite;}
    }
  }

  .header {
    position: relative;
    z-index: 10;
    height: 60px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(sm) {
      height: 80px;
    }

    .logo{
      margin-left: 20px;

      @include breakpoint(md) {
        margin-left: 80px;
      }
    }
    .openzoneoldSvg{
      width: 140px;

      @include breakpoint(sm) {
        display: none;
      }

      .pathOpen, .pathZone{
        fill: white;
      }
    }
    .openzoneoffSvg{
      display: none;

      @include breakpoint(sm) {
        display: block;
        width: 180px;
        .pathOpen, .pathZone{
          fill: white;
        }
      }
    }

    .edition{
     display: none;
     @include breakpoint(sm) {
       display: block;
     }
      margin-bottom: 0;
      margin-top: 5px;
      @include font-format('date');
      color: $white;
      opacity: .6;
    }
    .lang{
      margin-right: 20px;

      @include breakpoint(md) {
        margin-right: 80px;
      }

      color: white;
      .label:first-of-type{
        margin-right: 10px;
      }

      .label:last-child{
        margin-left: 10px;
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 22px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: .4s;
      background-image: linear-gradient(to right, $green 0%, $violet 100%);
      border: solid 1px white;

      &:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 28px;
        left: 0px;
        bottom: -1px;
        background-color: white;
        transition: .4s;
      }

      &.round {
        border-radius: 22px;
      }

      &.round:before {
        border-radius: 22px;
      }
    }

    input:checked + .slider:before {
      transform: translateX(12px);
    }
  }

  .godown{
    position: absolute;
    bottom: 20px;
    left: 50%;

    @include breakpoint(lg) {
      display: none;
    }

    .arrowDown{
      width: 25px;
      transform:rotate(90deg) translate(0px, 15px);

      .cls-2{
        fill: $white;
      }
    }


    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .horizontal-menu{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding: 0 80px;

    ul{
      display: none;

      @include breakpoint(lg) {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        align-items: center;
        justify-content: space-between;

        margin: 4px 0 0;
        width: 100%;
        height: 100%;
      }

      li{
        padding: 0;
        text-transform: uppercase;
        margin: 0 15px;
        font-size: 16px;
        line-height: 20px;

        .btn{
          @include font-format('orizmenu');

          color: $white;
          padding: 0;
          margin: 0;
          background: none;


          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .date{
    position: absolute;
    top: 100%;

    @include breakpoint(md) {
      top: 50%;
      transform: none;
    }

    p {
      transform: rotate(-90deg) translate(80px, 5px);
      transform-origin: top left;
      @include font-format('date');
      color: $white;
      opacity: .6;

      @include breakpoint(md) {
        transform: rotate(-90deg) translate(-50%, 50px);
      }
    }
  }

  .socials{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    display: none;

    @include breakpoint(md) {
      display: flex;
    }

  }
  .socialSvg{
    width: 28px;
    margin-bottom: 7px;

    .socialPath{fill:$white;}

    &:hover{
      .socialPath{fill:$grey;}
    }
  }
}
