$z-index: (
  plusBtn               : 300,
  overlayer             : 350,
  menu                  : 400,
  extra                 : 500,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
