@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";


.footer {
  @include sfumatura(1);

  .bigfooter{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 40px;

    .openzoneoffSvg{
      width: 180px;
      @include breakpoint(sm) {
        top: 25px;
      }
      @include breakpoint(md) {
        left: 80px;
      }
      .pathOpen, .pathZone{
        fill: white;
      }
    }

    p, a{
      margin-top: 20px;
      color: $white;
      max-width: 480px;
      text-align: center;
    }

    .donwload{
      margin: 15px 0 0 10px;
      color: $white;
      text-decoration: none;
      display: inline-block;

      span{
        border-bottom: 1px solid $white;
      }

      .downloadSvg{
        width: 18px;
        margin: 0 6px 0px 0;

        .downloadPathSvg{
          fill: $white;
        }
      }

      &:hover{
        .downloadSvg{
          .downloadArrowSvg{
            animation-name: bounceAlpha;
            animation-duration:1.4s;
            animation-iteration-count:infinite;
            animation-timing-function:linear;
          }
        }
      }
    }

    .videoLink{
      margin: 15px 5px 0px 0;
      color: $white;
      text-decoration: none;
      display: inline-block;

      span{
        border-bottom: 1px solid $white;
      }

      .playSvg{
        width: 23px;
        margin: 0 6px 0px 0;

        .playPathSvg{
          fill: $white;
        }
      }
    }
  }

  button.archive-btn {
    background: transparent;
    border: 0;
    color: $white;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    .arrowDown{
      width: 8px;
      margin: 0 0 1px 2px;
      transition: transform .4s;
      transform: rotate(90deg);
      .cls-2{
        fill: $white;
      }
    }

    &.open{
      .arrowDown{
        transform: rotate(270deg);
      }
    }
  }

  .archive-content{
    color: $white;
    overflow: hidden;
    transition: height .3s;
    height: 0px;

    &.collapsed{
      height: 30px;
    }
  }

  .socials{
    display: flex;
    flex-direction: row;
    display: flex;

    a{
      margin-right: 7px;

      &:last-child{
        margin-right: 0px;
      }
    }
  }

  .socialSvg{
    width: 28px;


    .socialPath{fill:$white;}

    &:hover{
      .socialPath{fill:$grey;}
    }
  }

  .smallfooter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $white;
    color: $dark-grey;
    padding: 10px;

    .footleft{
      max-width: 60%;
    }
    .footright{
      max-width: 40%;
    }

    p, a{
      @include font-format('smallFooter');
      display: inline;
      color: $dark-grey;
      margin-bottom: 0;
    }
  }
}
