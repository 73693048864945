@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";



.extra{
  @include z-index(extra);
  right: 0px;
  top: 0px;
  position: fixed;
  height: 0;
  height: 90%;
  left: 0;
  overflow: hidden;
  transform: translateY(-100%);
  transition: transform .5s;
  background-color: rgba(73,101,148,1);

  &.delucchi{
    background-color: rgba(98,96,136,1);
  }

  &.jace,
  &.gogreen{
    background-color: rgba(128,146,36,1);
  }

  &.open{
    transform: translateY(0%);
  }

  .plus{
    transform: rotate(45deg);

    bottom: 10px;
    right: 30px;
    position: absolute;

    .plussvg-1{stroke:$white; fill:none;}
    .plussvg-2{fill:$white;}
  }

  .extraBg{
    display: none;

    @include breakpoint(md) {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 60%;
      background-repeat: no-repeat;
      z-index:-1;
    }
  }

  &.zambon .extraBg{
    background-size: auto 100%;
    background-image: url(../images/zambon.png);
    background-position: left;
  }

  &.delucchi .extraBg{
    background-size: auto 100%;
    background-image: url(../images/delucchi.png);
    background-position: left;
  }

  &.mit .extraBg{
    background-size: auto 100%;
    background-image: url(../images/ovi.png);
    background-position: left;
  }

  &.torri .extraBg{
    background-size: auto 100%;
    background-image: url(../images/torri.png);
    background-position: left;
  }

  &.gogreen .extraBg{
    background-size: auto 100%;
    background-image: url(../images/go-green.png);
    background-position: left;
  }

  &.jace .extraBg{
    background-size: auto 100%;
    background-image: url(../images/jace/jace-1.png);
    background-position: left;
  }

  .extraContent{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;

    @include breakpoint(md) {
      flex-direction: row;
      justify-content: center;

    }

    .extra-left{
      padding: 20px 40px 20px 40px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      position: relative;

      @include breakpoint(sm) {
        padding: 40px 60px 0px 60px;
        flex-direction: row;

      }

      @include breakpoint(md) {
        align-items: flex-end;
        flex-direction: column;
        padding: $size-l 0 80px 40px;
      }

      .namerole{
        text-align: left;
      }

      p.name{
        @include font-format('extra_name');
        color: $white;
        text-align: left;
        @include breakpoint(md) {
          text-align: right;
        }
      }
      p.role{
        @include font-format('extra_role');
        color: $white;
        text-align: left;
        @include breakpoint(md) {
          text-align: right;
        }
        margin-bottom: 0;
      }

      a.playVideo{
        color: $white;
        text-decoration: none;
        @include font-format('playvideo');
        display: flex;
        align-items: center;
        max-width: 50%;

        @include breakpoint(md) {
          align-self: center;
        }

        @include breakpoint-down(md) {
          .nosm{
            display: none;
          }
        }

        .play{
          margin-right: 10px;
          @include breakpoint-down(md) {
            .playSvg{
              width: 40px;
            }
          }
        }

        .link{
          margin-right: 10px;
          @include breakpoint-down(md) {
            .linkSvg{
              width: 40px;
            }
          }
        }

        &.grey{
          color: $grey;
        }

        @include breakpoint(md) {
          max-width: 80%;
        }
      }
    }

    .extra-right{
      padding: 20px 20px $size-xl 20px;
      position: relative;
      height: calc(100% - 140px);

      @include breakpoint(sm) {
        padding: 40px $size-m $size-xl $size-m;
        height: calc(100% - 120px);

      }

      @include breakpoint(md) {
        padding: 60px $size-m 80px;
        height: auto;
      }

      img.build1{
        position: absolute;
        top: -30px;
        right: 150px;
        opacity: .4;
      }

      img.build2{
        position: absolute;
        top: 200px;
        right: 50px;

        opacity: .4;
      }
      img.build3{
        position: absolute;
        top: 400px;
        right: 190px;
        opacity: .2;
      }

      .col-text {
        padding: 0 $size-s;
      }

      .col-data {
        padding: 0 ;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 $size-s 30px;

        >* {
          display: flex;
          flex-direction: column;
          width: calc(33% - 10px);

          h4{
            @include font-format('extradatalabel');
            color: $white;
            margin: 0px 0 10px 0;
          }

          h5{
            @include font-format('extradata');
            color: $white;
            margin-top: 5px;
          }
        }
      }

      hr{
        color: $white;
        margin: 0 20px 40px 20px;
        width: calc(100% - 40px);
      }
      p{
        @include font-format('letter');
        color: $white;
      }
    }
  }

  &.zambon .extraContent{
    background: linear-gradient(90deg, rgba(73,101,148,0) 15%, rgba(73,101,148,1) 40%, rgba(73,101,148,1) 100%);
  }

  &.delucchi .extraContent{
    background: linear-gradient(90deg, rgba(98,96,136,0) 15%, rgba(98,96,136,1) 40%, rgba(98,96,136,1) 100%);
  }

  &.mit .extraContent{
    background: linear-gradient(90deg, rgba(73,101,148,0) 15%, rgba(73,101,148,1) 40%, rgba(73,101,148,1) 100%);
  }

  &.torri .extraContent{
    background: linear-gradient(90deg, rgba(73,101,148,0) 15%, rgba(73,101,148,1) 40%, rgba(73,101,148,1) 100%);
  }

  &.gogreen .extraContent,
  &.jace .extraContent{
    background: linear-gradient(90deg, rgba(128,146,36,0) 15%, rgba(128,146,36,1) 40%, rgba(128,146,36,1) 100%);
  }
}
