@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";

@keyframes flashing {
    0% { opacity: .3; }
    50% { opacity: .8; }
    100% { opacity: .3; }
}

.idea{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;

  .square2{

    height: 100vw;

    @include breakpoint(sm) {
      height: 50vw;
    }

    @include breakpoint(lg) {
      height: 33vw;
    }

    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .8s;
    }

    &:hover{
      img {
        transform-origin: center center;
        transform: scale(1.2);
      }
    }

    h3{
      color: $white;
      @include font-format('names');
    }

    h4{
      color: $white;
      @include font-format('role');
    }

    p.quote{
      padding: 10px;
      color: $white;
      @include font-format('quotes');
    }
  }

  .square3{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include sfumatura(2);


    height: 100vw;

    @include breakpoint(sm) {
      height: 50vw;
    }

    @include breakpoint(lg) {
      height: 33vw;
    }

    h3{
      @include font-format('numbersbig');
      color: $white;
    }

    .overlay{
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: transform 1s;
      background-color:rgba(255, 255, 255, .8);
      transform: translateY(100%);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;

      h4{
        @include font-format('numbersOverlay');
        text-align: center;
      }
    }

    &:hover{
      .overlay{
        transform: translateY(0%);
      }
    }
  }

  .square4{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include sfumatura(2);
    height: auto;

    @include breakpoint(sm) {
      height: 50vw;
    }

    @include breakpoint(lg) {
      height: 33vw;
    }


    h3{
      padding: 30px;
      max-width: 830px;
      @include font-format('delucchiText');
      color: $white;
    }
  }

  .square4bis{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include sfumatura(5);
    height: auto;

    @include breakpoint(sm) {
      height: 50vw;
    }

    @include breakpoint(lg) {
      height: 33vw;
    }


    .videoLink{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;

      .playSvg{
        width: 80px;
      }

      .playPathSvg{
        fill: $white;
      }

      span{
        @include font-format('buttonLabel');
        color: $white;
        margin-top: 10px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .8s;
    }

    &:hover{
      img {
        transform-origin: center center;
        transform: scale(1.2);
      }
    }

    .buildings{
      opacity: .4;

      > *{
        position: absolute;
        transform: scale(.3);
      }

      .build1{ left: -50%; top: 20%; }
      .build2{ right: -40%; top: 10%; }
      .build3{ left: 40%; bottom: -10%; }
      .build4{ right: -25%; bottom: -35%; }
      .build5{ left: -40%; bottom: 10%; }
      .build6{ left: 10%; top: -20%; }

       @include breakpoint(xl) {
         > *{
           transform: scale(.4);
         }

         .build1{ left: -40%; top: 20%; }
         .build2{ right: -20%; top: 10%; }
         .build3{ left: 40%; bottom: 0%; }
         .build4{ right: -15%; bottom: -15%; }
         .build5{ left: -30%; bottom: 20%; }
         .build6{ left: 10%; top: -10%; }
       }

       @include breakpoint(xxl) {
         > *{
           transform: scale(.7);
         }
       }
    }
  }

  .square5{
    position: relative;
    overflow: hidden;
    cursor: pointer;

    height: 100vw;

    @include breakpoint(sm) {
      height: 50vw;
    }

    @include breakpoint(lg) {
      height: 33vw;
    }

    h3{
      color: $white;
      @include font-format('names');
    }

    h4{
      color: $white;
      @include font-format('role');
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .8s;
    }

    &:hover{
      img {
        transform-origin: center center;
        transform: scale(1.2);
      }
    }
  }

  .square6{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $green;
    position:relative;
    height: 100vw;

    @include breakpoint(sm) {
      height: 66vw;
    }

    @include breakpoint(lg) {
      height: 50vw;
    }

    .swiper {
      width: 100%;
      height: 100%;

      .swiper-slide{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        background: $green;
      }

      .imgContainer{
        width: 100%;
        height: calc(100% - 150px);
        overflow:hidden;
        position: relative;

        img{
          top: 0;
          left: 0;
          position:absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        h4{
          z-index: 2;
          position: absolute; bottom: 0;
          left: 30px;
          @include font-format('slideBuildingsTitle');
          color:$white;
        }
      }

      .content{
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        height: 150px;

        > *{
          color: #fff;
          width: 50%;

          &:nth-child(1){
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: solid 1px $white;


            h5{
              @include font-format('slideBuildingsInfo');
            }
            h6{
              @include font-format('slideBuildingsNumberBig');
            }
          }
          &:nth-child(2){
            display: flex;
            flex-direction: column;
            align-items: center;

            h5{
              @include font-format('slideBuildingsInfo');
            }
            h6{
              @include font-format('slideBuildingsNumberSmall');
            }
          }
        }
      }
    }
  }

  .square7{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    @include sfumatura(2)

    @include breakpoint(sm) {
      height: 66vw;
    }

    @include breakpoint(lg) {
      height: 50vw;
    }

    .mapContainer{
      width: 60%;
      position: relative;
    }

    .swiper{
      width: 80%;

      @include breakpoint(sm) {
        width: 100%;
      }

      @include breakpoint(lg) {
        width: 65%;
      }

      @include breakpoint(xl) {
        width: 60%;
      }


      .swiper-slide{
        visibility: hidden;
      }
      .swiper-slide-visible{
        visibility: visible;
      }
    }

    .map{
      width: 100%;
    }

    .point{
      position: absolute;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      background-color: $green;
      transform: translate(-50%, -50%);
      animation: flashing 3s linear infinite;


      &.point0{
        left: 65.1%;
        top: 37.4%;
      }
      &.point1{
        left: 59.2%;
        top: 75.1%;
      }
      &.point2{
        left: 66.3%;
        top: 57.6%;
      }
      &.point3{
        left: 35.7%;
        top: 56.9%;
      }
      &.point4{
        left: 39.3%;
        top: 63.6%;
      }
      &.point5{
        left: 63.9%;
        top: 57.6%;
      }
    }
  }
}
