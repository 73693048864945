@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";

@keyframes flashing {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.talk{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;
  background-color: #dadfc0;


  .square2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 66vw;
    background-color: #dadfc0;
    position: relative;
    cursor: pointer;
    padding: 30px;

    @include breakpoint(sm) {
      height: 33vw;
    }

    .gogreenSvg{
      max-width: 80%;
    }

    .benvivereSvg{
      margin-top: 50px;
      max-width: 80%;

      .cls-1{fill:#b0b832;}
    }
  }

  .square3{
    padding: 20px 60px;
    min-height: 100vw;
    position: relative;
    @include sfumatura(3);

    @include breakpoint(sm) {
      min-height: 66vw;
    }

    @include breakpoint(md) {
      min-height: 0;
    }

    @include breakpoint(lg) {
      padding: 0px 60px;
    }

    .swiper-button-prev, .swiper-button-next{
      color: $white;
    }

    .swiper{
      height: 100%;

      .swiper-slide{
        display: flex;
        flex-direction: row;
        align-items: center;

        @include breakpoint(lg) {
          flex-direction: column;
        }
        justify-content: center;
      }
    }

    .talkBox{
      width: 100%;
      margin: 0 5px;
      align-items: center;
      height: auto;

      @include breakpoint(md) {
        width: 50%;
      }

      @include breakpoint(lg) {
        width: 100%;
        margin: 0 0 20px;
      }

      &.arrow_tr .cont,
      &.arrow_tl .cont,
      &.arrow_br .cont,
      &.arrow_bl .cont{
        border: solid 4px $white;
        position: relative;

        .imageButton{
          display: flex;
          align-items: space-between;
          justify-content: space-between;

          img{
            width: 70px;
            height: 70px;
            margin-top: -15px;
            border-radius: 8px;
          }

          .link{
            margin-top: -15px;
          }
        }

        h3{
          margin-top: 10px;
          @include font-format('talk_name');
          color: $dark-grey;
        }

        h4{
          display: block;
          @include font-format('talk_position');
          color: $dark-grey;
        }

        .extract{
          display: block;
          margin-top: 10px;
          @include font-format('talk_extract');
          color: $white;

          @include breakpoint(lg) {
            display: none;
          }

          @include breakpoint(xl) {
            display: block;
          }
        }

        .description{
            display: block;
            margin-top: 10px;
            @include font-format('talk_description');
            color: $white;

        }
      }

      &.arrow_tr,
      &.arrow_tl{
        margin-top: 70px;

        .cont{
          border-bottom-left-radius: 40px;
          border-bottom-right-radius: 40px;
          border-top: 0px;
          padding: 0px 10px 15px 10px;

          @include breakpoint(xxxl) {
            padding: 0px 20px 15px 20px;
          }
        }
      }

      &.arrow_tr .cont{
        &::before{
          content: "";
          position: absolute;
          top: -40px;
          left: -4px;
          height: 40px;
          width: calc(48% + 5px);
          border-top-left-radius: 40px;
          border-top: solid 4px $white;
          border-left: solid 4px $white;
        }

        &::after{
          content: "";
          position: absolute;
          top: -73px;
          right: -4px;
          height: 80px;
          width: calc(52% + 5px);
          transform: translateY(-0.5px);
          background: url(../svg/ballonArrow.svg) no-repeat top right;
          background-size: 361.42px 357.84px;
        }
      }

      &.arrow_tl .cont{
        &::before{
          content: "";
          position: absolute;
          top: -40px;
          right: -4px;
          height: 40px;
          width: calc(48% + 5px);
          border-top-right-radius: 40px;
          border-top: solid 4px $white;
          border-right: solid 4px $white;
        }

        &::after{
          content: "";
          position: absolute;
          top: -73px;
          left: -4px;
          height: 80px;
          width: calc(52% + 5px);
          background: url(../svg/ballonArrow.svg) no-repeat top right;
          background-size: 361.42px 357.84px;
          transform: scaleX(-1) translateY(-0.5px);
        }
      }

      &.arrow_br,
      &.arrow_bl{
        margin-bottom: 70px;

        .cont{
          border-top-left-radius: 40px;
          border-top-right-radius: 40px;
          border-bottom: 0;
          padding: 35px 10px 0px 10px;

          .description{
            @include breakpoint(xxxl) {
              margin-bottom: 0px;
            }
          }

          @include breakpoint(xxxl) {
            padding: 35px 20px 0px 20px;
          }
        }
      }

      &.arrow_br .cont{
        &::before{
          content: "";
          position: absolute;
          bottom: -37px;
          left: -4px;
          height: 40px;
          width: calc(48% + 5px);
          border-bottom-left-radius: 40px;
          border-bottom: solid 4px $white;
          border-left: solid 4px $white;
        }

        &::after{
          content: "";
          position: absolute;
          bottom: -70px;
          right: -4px;
          height: 80px;
          width: calc(52% + 5px);
          background: url(../svg/ballonArrow.svg) no-repeat top right;
          background-size: 361.42px 357.84px;
          transform: scaleY(-1) translateY(-0.5px);
        }
      }

      &.arrow_bl .cont{
        &::before{
          content: "";
          position: absolute;
          bottom: -40px;
          right: -4px;
          height: 40px;
          width: calc(48% + 5px);
          border-bottom-right-radius: 40px;
          border-bottom: solid 4px $white;
          border-right: solid 4px $white;
        }

        &::after{
          content: "";
          position: absolute;
          bottom: -73px;
          left: -4px;
          height: 80px;
          width: calc(52% + 5px);
          background: url(../svg/ballonArrow.svg) no-repeat top right;
          background-size: 361.42px 357.84px;
          transform: scaleX(-1) scaleY(-1) translateY(-0.5px);
        }
      }
    }
  }
}
