//
// Variables
// --------------------------------------------------

$font-sans:              tStarPro, sans-serif; //300 400 700 800

$font-light:              300;
$font-normal:             400;
$font-strong:             700;
$font-heavy:              800;

$font-size-zs:            14px; $line-height-zs:           20px;

$font-size-xxxxs:         13px; $line-height-xxxxs:           15px;
$font-size-xxxs:          16px; $line-height-xxxs:           20px;
$font-size-xxs:           19px; $line-height-xxs:           24px;
$font-size-xs:            20px; $line-height-xs:           28px;
$font-size-s:             24px; $line-height-s:            32px;
$font-size-ms:            30px; $line-height-ms:           36px;
$font-size-m:             40px; $line-height-m:            50px;
$font-size-l:             50px; $line-height-l:            60px; // h2
$font-size-lplus:         50px; $line-height-lplus:           70px; // h3

$font-size-xl:            72px; $line-height-xl:           78px; // h3
$font-size-xxl:           86px; $line-height-xxl:           86px; // h3


$letter-spacing-s:         0.01em; // h2
$letter-spacing-m:         0.05em; // **
$letter-spacing-l:         0.10em; // **
$letter-spacing-xl:         0.20em; // **


// COLORS
$black:                   #000000;
$white:                   #ffffff;
$light-grey:              #f2f4f2;
$dark-grey:               #333333;
$grey:                    #333333;


$green:                   #789325;
$green_light:             #87A862;
$green_dark:              #455d45;

$blue:                    #414d7a;
$blue_light:              #5F95C1;
$violet:                  #6a638c;
$violet_light:            #93859d;


$size_xxs: 12px;
$size_xs: 12px;
$size_s: 20px;
$size_m: 40px;
$size_l: 60px;
$size_xl: 80px;
$size_xxl: 80px;
