@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";


@keyframes flashing {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.projects{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;

  .square2{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../images/network.png);
    background-size: cover;
    position: relative;
    height: 66vw;

    @include breakpoint(md) {
      height: 33vw;
    }

    .svgOpenzone{
      width: 175px;

      @include breakpoint(lg) {
        width: 230px;
      }

      .pathOpen{
        fill: #706f6f;
      }

      .pathZone{
        fill: #b4bd10;
      }
    }

    .cluster{
      position:absolute;
      width: 150px;
      bottom: 20%;
      right: 10%;

      @include breakpoint(lg) {
        width: 200px;
        bottom: 15%;
        right: 15%;
      }
      @include breakpoint(xl) {
        bottom: 20%;
        right: 20%;
      }
    }

    .innovup{
      position:absolute;
      width: 110px;
      top: 20%;
      left: 10%;

      @include breakpoint(lg) {
        width: 160px;
        top: 15%;
        left: 15%;
      }
      @include breakpoint(xl) {
        top: 20%;
        left: 20%;
      }
    }
    .iasp{
      position:absolute;
      width: 145px;
      bottom: 20%;
      left: 10%;

      @include breakpoint(lg) {
        width: 175px;
        bottom: 15%;
        left: 15%;
      }
      @include breakpoint(xl) {
        bottom: 20%;
        left: 20%;
      }
    }
    .cerb{
      position:absolute;
      width: 100px;
      top: 20%;
      right: 10%;

      @include breakpoint(lg) {
        width: 140px;
        top: 15%;
        right: 15%;
      }
      @include breakpoint(xl) {
        top: 20%;
        right: 20%;
      }
    }
  }

  .square3{
    position: relative;

    cursor: pointer;
    @include sfumatura(4);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s;

    height: 66vw;

    @include breakpoint(md) {
      height: 33vw;
    }


    @include breakpoint(xl) {
      height: 22vw;
    }

    .pattern{
      background-image: url(../images/pattern.png);
      background-size: 14px;
      width: 100%;
      height: 100%;
      padding: 30px;
    }

    .plus{

      .plussvg-1{ fill: rgba(255, 255, 255, 0.3)}

    }

    .mit{
      width: 140px;
      position:absolute;
      top: 30px;
      left: 30px;

      @include breakpoint(sm) {
        width: 200px;
      }

      @include breakpoint(md) {
        width: 120px;
      }

      @include breakpoint(lg) {
        width: 160px;
      }

      @include breakpoint(xxl) {
        width: 200px;
      }
    }

    .oz{
      width: 50px;
      position:absolute;
      top: 30px;
      right: 30px;

      @include breakpoint(md) {
        width: 40px;
      }

      @include breakpoint(xl) {
        width: 50px;
      }
    }

    .lifescience{
      position:absolute;
      bottom: 70px;
      left: 30px;
      max-width: calc(100% - 60px);
      opacity: .7;

      @include breakpoint(lg) {
        bottom: 90px;
      }
    }
  }

  .square4{
    background-image: url(../images/iosono.png);
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    height: 66vw;

    @include breakpoint(sm) {
      height: 50vw;
    }

    @include breakpoint(md) {
      height: 33vw;
    }

    @include breakpoint(xl) {
      height: 22vw;
    }


    .iosonoFooter{
      background-color: rgba(255,255,255,.7);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 100px;

      .iosonoSvg{
        margin: 10px 30px;
        width: 150px;

        @include breakpoint(sm) {
          width: 180px;
        }

        .cls-1{fill:#f9af2d;}
      }

      .play{
        margin: 0 30px;
      }
    }

  }

  .square5{
    background-image: url(../images/tuspark.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 66vw;

    @include breakpoint(sm) {
      height: 50vw;
    }

    @include breakpoint(md) {
      height: 33vw;
    }

    @include breakpoint(xl) {
      height: 22vw;
    }

    .tusparkContent{
      padding: 30px 30px 0;
      h3{
        color: $white;
        @include font-format('tusparkTitle');
      }
      p{
        color: $white;
        @include font-format('tusparkContent');
      }
    }


    .tusparkFooter{
      background-color: rgba(255,255,255,.4);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 100px;

      .tusparkLogo{
        width: 160px;
        margin: 10px 30px;
      }

      .link{
        margin: 0 30px;
      }
    }
  }

  .square6{
    background-color: lighten($blue, 50%);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    @include breakpoint(xl) {
      height: 33vw;
    }

    img{
      width: 70%;
      max-width: 250px;
    }

    p{
      display: none;
      color: $blue;
      margin: 20px 0 0;
      @include font-format('labsSub');

      @include breakpoint(sm) {
        display: block;
      }

    }
  }

  .square-lab{
    background-size: cover;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 50vw;

    @include breakpoint(md) {
      height: 33vw;
    }

    @include breakpoint(lg) {
      height: 25vw;
    }

    @include breakpoint(xl) {
      height: 16.5vw;
    }

    .overlab{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .5s;
      background-color: rgba(73,101,148,.5);

      .overlabcontent{
        padding: 20px;
        height: 100%;
        visibility: hidden;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        p,a{
          @include font-format('labsCube');
          color: $white;
          text-align: left;
        }
        p{
          text-transform: uppercase;
        }
      }
    }

    &:hover,
    &.open{
      .overlab{

        .overlabcontent{
        }
      }
    }


    &.open{
      cursor: default;

      .overlab{
        background-color: rgba(73,101,148,.8);
        .overlabcontent{
          visibility: visible;
        }
      }
    }
  }

  .square7{
    background-image: url(../images/lab/lab1.png);
  }

  .square8{
    background-image: url(../images/lab/lab2.png);
  }

  .square9{
    background-image: url(../images/lab/lab3.png);
  }

  .square10{
    background-image: url(../images/lab/lab4.png);
  }

  .square11{
    background-image: url(../images/lab/lab5.png);
  }

  .square12{
    background-image: url(../images/lab/lab6.png);
  }

  .square13{
    background-image: url(../images/lab/lab7.png);
  }

  .square14{
    background-color: lighten($blue, 50%);
    height: 50vw;

    @include breakpoint(md) {
      height: 33vw;
    }

    @include breakpoint(lg) {
      height: 25vw;
    }

    @include breakpoint(xl) {
      height: 16.5vw;
    }


    a{
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: $blue;

      .link{
        margin: 0 auto;
      }

      span{
        margin-top: 10px;
        display: block;
        color: $blue;
      }
    }
  }

}
