@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";


@keyframes bounceAlpha {
  0% {transform: translateY(0px) }
  25%{transform: translateY(100%) }
  26%{transform: translateY(-100%)}
  55%{transform: translateY(0px)}
}

.menu{
  @include z-index(menu);

  opacity: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  transition: all .4s;
  visibility: hidden;

  &.show{
    visibility: visible;
    opacity: 1;
  }

  .menuContainer{
    background: $green;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    padding: 20px;
    //min-width: 340px;
    transform: translateX(-100%);
    display: flex;
    align-items: center;
    justify-content: center;

    .menuHead{
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      padding: 15px 20px 18px;
      text-align: left;


      .openzoneoffSvg{
        width: 180px; //140

        .pathOpen, .pathZone{
          fill: white;
        }
      }
    }


    ul{
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      margin: 0;
      min-width: 250px;

      li{
        margin: 0px;
        padding: 16px 0 14px;
        border-bottom: solid 1px $white;

        .btn{
          @include font-format('vertmenu');
          text-align: left;
          color: $white;
          padding: 0;
          margin: 0;
          background: none;


          &:focus {
            outline: none;
            box-shadow: none;
          }

          &:hover{
            color: $grey;
          }
        }

        body.body_idea &.menu_idea,
        body.body_campus &.menu_campus,
        body.body_talk &.menu_talk,
        body.body_projects &.menu_projects,
        body.body_zoner &.menu_zoner{
          .btn{
            color: $grey;
            font-weight: $font-strong;
          }
        }

        &:last-child{
          border-bottom: 0;
        }
      }
    }


    .social{
      position: absolute;
      bottom: 30px;
      left: 20px;

      a.download{
        margin: 5px 0 25px 0px;
        color: $white;
        @include font-format('follow');
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        text-decoration: none;

        span{
          border-bottom: 1px solid $white;
        }

        .downloadSvg{
          width: 18px;
          margin: 0 6px 0px 0;

          .downloadPathSvg{
            fill: $white;
          }
        }

        &:hover{
          .downloadSvg{
            .downloadArrowSvg{
              animation-name: bounceAlpha;
              animation-duration:1.4s;
              animation-iteration-count:infinite;
              animation-timing-function:linear;
            }
          }
        }
      }

      .videoLink{
        margin-bottom: 5px;
        color: $white;
        text-decoration: none;
        display: inline-block;
        @include font-format('follow');

        @media screen and ( max-height: 570px ){
          display: none;
        }


        span{
          border-bottom: 1px solid $white;
        }

        .playSvg{
          width: 23px;
          margin: 0 6px 0px 0;

          .playPathSvg{
            fill: $white;
          }
        }
      }

      .socialSvg{
        width: 28px;
        margin-right: 7px;

        .socialPath{fill:$white;}

        &:hover{
          .socialPath{fill:$grey;}
        }
      }
    }

    .burger{
      position: absolute;
      right: -148px;
      top: 0;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;

      // &:after{
      //   content:"NO";
      //   position: absolute;
      //   bottom: 0;
      //   right: 2px;;
      //   font-size: 12px;
      //   z-index: 999;
      //
      //   @include breakpoint(sm) {
      //     content:"SM";
      //   }
      //
      //   @include breakpoint(md) {
      //     content:"MD";
      //   }
      //
      //   @include breakpoint(lg) {
      //     content:"LG";
      //   }
      //
      //   @include breakpoint(xl) {
      //     content:"XL";
      //   }
      //
      //   @include breakpoint(xxl) {
      //     content:"XXL";
      //   }
      //
      //   @include breakpoint(xxxl) {
      //     content:"XXXL";
      //   }
      // }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .burgersvg{

        .cls-1{fill: $green;}
        .cls-2,.cls-3,.cls-4{ transform-origin: center; fill:none;stroke: $white; stroke-width:2px; transition: all .4s;}
      }

      .ozetasvg{
        width: 74px;


        .cls-1{fill: $white;}
        .cls-2{fill: $green;}
      }
    }

    &.open{ //menuContainer
      .burger{
        right: -74px;
        .burgersvg{
          .cls-3{opacity: 0;}
          .cls-2{ transform: rotate(45deg) translateY(7px)}
          .cls-4{ transform: rotate(-45deg) translateY(-8px)}

        }

        .ozetasvg{
          display: none;
        }
      }
    }
  }

}
