@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";


@keyframes flashing {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.zoner{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;

  .square2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $size_m;
    @include sfumatura(2);

    @include breakpoint(lg) {
      min-height: 0;
      height: 33vw;
    }

    h4{
      color: $white;
      text-align: left;
      width: 100%;
      @include font-format('zonerlisttile');
    }

    .zonerTextList{
      text-align: left;
      width: 100%;
      color: $white;
      margin-top: $size_m;

      span{
        @include font-format('zonerlistspan');
        color: $white;
      }

      a{
        @include font-format('zonerlist');
        color: $white;
      }
    }
  }

  .square-zoner{
    min-height: 33vw;
    padding: 50px 20px 20px;
    position: relative;
    text-decoration: none;
    overflow: hidden;

    @include breakpoint(xl) {
      padding: 60px 50px;
    }

    .bg{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      transition: transform .4s;
    }

    &:hover{
      .bg{
        transform: scale(1.1);
      }
    }

    .zonerContent{
      background-color: rgba(255,255,255,.8);
      padding: 30px 30px 25px;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      h4{
        position: absolute;
        top: 0px;
        left: 0px;
        transform: translate(0, -100%);
        line-height: .9em;
        color: $white;
        text-transform: uppercase;
      }

      p{
        font-size: 16px;

        @include breakpoint(xl) {
          font-size: 18px;

        }




        .author{
          display: block;
          margin-top: 20px;
          font-weight: $font-strong;
          color: $dark-grey;
        }
      }
      .logo{
        bottom: 30px;
        right: 30px;
      }
    }
  }

  .square3 .logo{ width: 222px;  }
  .square4 .logo{ width: 160px;  }
  .square5 .logo{ width: 216px;  }

  .square6 .logo{ width: 131px;  }
  .square7 .logo{ width: 172px;  }
  .square8 .logo{ width: 220px;  }

  .square9 .logo{ width: 222px;  }
  .square10 .logo{ width: 148px;  }
  .square11 .logo{ width: 212px;  }

  .square12{
    display: none;

    @include breakpoint(sm) {
      display: blocK
    }

    @include breakpoint(lg) {
      display: none;
    }


    a{
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: $dark-grey;

      .link{
        margin: 0 auto;
      }

      span{
        margin-top: 10px;
        display: block;
        color: $dark-grey;
      }
    }
  }
}
