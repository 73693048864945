// Defining values min-width 1200-992-768

@mixin background-opacity($color, $opacity: 1) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin breakpoint-down($point) {
  @if $point == xxl {
    @media (max-width: 1399.98px) { @content ; }
  }
  @if $point == xl {
    @media (max-width: 1199.98px) { @content ; }
  }
  @if $point == lg {
    @media (max-width: 991.98px) { @content ; }
  }
  @if $point == md {
    @media (max-width: 767.98px) { @content ; }
  }
  @if $point == sm {
    @media (max-width: 575.98px) { @content ; }
  }
}


@mixin breakpoint($point) {
  @if $point == xxxl {
    @media (min-width: 1600px) { @content ; }
  }
  @if $point == xxl {
    @media (min-width: 1400px) { @content ; }
  }
  @if $point == xl {
    @media (min-width: 1200px) { @content ; }
  }
  @if $point == lg {
    @media (min-width: 992px) { @content ; }
  }
  @if $point == md {
    @media (min-width: 768px) { @content ; }
  }
  @if $point == sm {
    @media (min-width: 576px) { @content ; }
  }
}

@mixin sfumatura($num) {
  @if $num == 1 {
    background-image: linear-gradient(to right, $green 0%, $green_dark 25%, $blue 50%, $violet 75%, $violet_light 100%);
  }
  @if $num == 2 {
    background-image: linear-gradient(to right, $violet 0%, $blue 100%);
  }
  @if $num == 3 {
    background-image: linear-gradient(to right, $green_light 0%, $blue_light 100%);
  }

  @if $num == 4 {
    background-image: linear-gradient(to right, #9fb84a 0%, #568168 100%);
  }
  @if $num == 5 {
    background-image: linear-gradient(to right, $green_light 0%, #357470 25%, $blue_light 60%, $violet_light 100%)
  }

}

@mixin font-format($style) {
  @if $style == 'intro' {
    font-size: $font-size-ms;
    line-height: $line-height-ms;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-m;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: $font-size-m;
      line-height: $line-height-m;
    }
  }

  @if $style == 'date' {
    font-size: $font-size-xxxxs;
    line-height: $line-height-xxxxs;
    font-weight: $font-light;
    letter-spacing: $letter-spacing-xl;
  }

  @if $style == 'titles' {
    font-size: $font-size-m;
    line-height: $line-height-m;
    font-weight: $font-heavy;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
    background-color: $green;
    @include sfumatura(1);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    @include breakpoint(sm) {
      font-size: $font-size-l;
      line-height: $line-height-l;
    }

    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }

    @include breakpoint(lg) {
      font-size: $font-size-l;
      line-height: $line-height-l;
    }
    @include breakpoint(xl) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }
  }

  @if $style == 'titles_projects' {
    font-size: $font-size-ms;
    line-height: $line-height-ms;
    font-weight: $font-heavy;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
    background-color: $green;
    @include sfumatura(1);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    @include breakpoint(sm) {
      font-size: $font-size-l;
      line-height: $line-height-l;
    }

    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }

    @include breakpoint(lg) {
      font-size: $font-size-m;
      line-height: $line-height-m;
    }
    @include breakpoint(xl) {
      font-size: $font-size-lplus;
      line-height: $line-height-l;
    }

    @include breakpoint(xxxl) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }
  }

  @if $style == 'tusparkTitle' {
    font-size: $font-size-m;
    line-height: $line-height-m;

    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
    @include breakpoint(xxl) {
      font-size: $font-size-l;
      line-height: $line-height-l;
    }
  }

  @if $style == 'smallFooter' {
    font-size: $font-size-xxxxs;
    line-height: $line-height-xxxxs;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;
  }

  @if $style == 'labsSub' {
    font-size: $font-size-ms;
    line-height: $line-height-ms;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;
  }

  @if $style == 'buttonLabel' {
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
    white-space: nowrap;
  }

  @if $style == 'labsCube' {
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;
  }


  @if $style == 'tusparkContent' {
    font-size: $font-size-s;
    line-height: $line-height-s;

    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;
    @include breakpoint(xxl) {
      font-size: $font-size-s;
      line-height: $line-height-s;
    }
  }

  @if $style == 'numbersTitle' {
    font-size: $font-size-l;
    line-height: $line-height-l;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
  }


  @if $style == 'numbersSubtitle' {
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
  }

  @if $style == 'numbersbig' {
    font-weight: $font-heavy;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;

    font-size: $font-size-l;
    line-height: $line-height-l;
    sub{
      font-size: $font-size-ms;
    }

    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
      sub{
        font-size: $font-size-m;
      }
    }

    @include breakpoint(xl) {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
      sub{
        font-size: $font-size-l;
      }
    }
  }

  @if $style == 'slideBuildingsTitle' {
    font-size: $font-size-m;
    line-height: $line-height-m;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
  }

  @if $style == 'slideBuildingsInfo' {
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;

    @include breakpoint(md) {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
    }
  }

  @if $style == 'slideBuildingsNumberSmall' {
    font-size: $font-size-l;
    line-height: $line-height-l;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
  }

  @if $style == 'slideBuildingsNumberBig' {
    font-size: $font-size-l;
    line-height: $line-height-l;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
    margin-bottom: -7px;


    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
      margin-bottom: -10px;
    }
  }

  @if $style == 'delucchiText' {
    font-size: $font-size-s;
    line-height: $line-height-s;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;

    @include breakpoint(sm) {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
    }

    @include breakpoint(md) {
      font-size: $font-size-s;
      line-height: $line-height-s;
    }


    @include breakpoint(lg) {
      font-size: $font-size-s;
      line-height: $line-height-s;
    }

    @include breakpoint(xl) {
      font-size: $font-size-ms;
      line-height: $line-height-ms;
    }
  }

  @if $style == 'numbersOverlay' {
    font-size: $font-size-ms;
    line-height: $line-height-ms;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;

    background-color: $green;
    text-align: left;
    @include sfumatura(1);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    @include breakpoint(md) {
      font-size: $font-size-m;
      line-height: $line-height-m;
    }

    @include breakpoint(xl) {
      font-size: $font-size-l;
      line-height: $line-height-l;
    }
  }
  @if $style == 'names' {
    font-size: $font-size-s;
    line-height: $line-height-s;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
    @include breakpoint(xl) {
      font-size: $font-size-ms;
      line-height: $line-height-ms;
    }
  }

  @if $style == 'role' {
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;

    @include breakpoint(xl) {
      font-size: $font-size-xxs;
      line-height: $line-height-xxs;
    }
  }
  @if $style == 'quotes' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    letter-spacing: $letter-spacing-m;

    @include breakpoint(xl) {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
    }

    &:before{
      content: "“";
      position: absolute;
      top: 15px;
      left: -15px;
      font-size: $font-size-l;
      font-weight: $font-strong;
    }

    &:after{
      content: "”";
      position: absolute;
      bottom: -15px;
      right: -10px;
      font-size: $font-size-l;
      font-weight: $font-strong;
    }

    @include breakpoint(xl) {
      &:before{
        top: 10px;
        left: -30px;
        font-size: $font-size-xl;
      }

      &:after{
        bottom: -50px;
        right: -10px;
        font-size: $font-size-xl;
      }
    }

  }

  @if $style == 'caption' {
    font-size: $font-size-ms;
    line-height: $line-height-m;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-s;
  }
  @if $style == 'playvideo' {
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
  }

  @if $style == 'extradata' {
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;

    @include breakpoint(lg) {
      font-size: $font-size-ms;
      line-height: $line-height-ms;
    }
    @include breakpoint(xl) {
      font-size: $font-size-m;
      line-height: $line-height-m;
    }
    @include breakpoint(xxl) {
      font-size: $font-size-l;
      line-height: $line-height-l;
      font-weight: $font-heavy;
    }
  }

  @if $style == 'extradatalabel' {
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    letter-spacing: $letter-spacing-s;

    @include breakpoint(xl) {
      font-weight: $font-strong;
    }
  }

  @if $style == 'letter' {
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    font-weight: $font-light;
    letter-spacing: $letter-spacing-s;

    strong{
      font-weight: $font-strong;
    }

    strong.heavy{
      font-weight: $font-heavy;
    }

    strong.bigger{
      font-weight: $font-normal;
      font-size: $font-size-m;
      line-height: $line-height-m;
    }
  }
  @if $style == 'extra_name' {
    font-family: $font-sans;
    font-weight: $font-strong;
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    letter-spacing: $letter-spacing-m;
    margin-bottom: 0;
  }
  @if $style == 'extra_role' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    letter-spacing: $letter-spacing-s;
  }

  @if $style == 'talk_name' {
    font-family: $font-sans;
    font-weight: $font-strong;
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;

  }

  @if $style == 'talk_position' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-zs;
    line-height: $line-height-zs;
    letter-spacing: $letter-spacing-s;
  }

  @if $style == 'talk_extract' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-zs;
    line-height: $line-height-zs;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
  }

  @if $style == 'talk_description' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-xxxxs;
    line-height: $line-height-zs;
    letter-spacing: $letter-spacing-s;
  }


  @if $style == 'orizmenu' {
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-strong;
    letter-spacing: $letter-spacing-s;
    text-transform: uppercase;
  }

  @if $style == 'vertmenu' {
    font-size: $font-size-xs;
    line-height: $line-height-xxxs;
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-m;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: $font-size-s;
      line-height: $line-height-xxs;
    }
  }

  @if $style == 'follow' {
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    font-weight: $font-light;
    letter-spacing: $letter-spacing-m;
  }


  @if $style == 'zonerlisttile' {
    font-family: $font-sans;
    font-weight: $font-strong;
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: $letter-spacing-s;
    text-decoration: none;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: $font-size-ms;
      line-height: $line-height-ms;
    }

    @include breakpoint(xl) {
      font-size: $font-size-m;
      line-height: $line-height-m;
    }
  }


  @if $style == 'zonerlist' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    letter-spacing: $letter-spacing-s;
    text-decoration: none;
    text-transform: uppercase;

    &:hover{
      border-bottom: solid 1px $white;
    }

    @include breakpoint(sm) {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
    }

    @include breakpoint(xl) {
      font-size: $font-size-s;
      letter-spacing: $letter-spacing-s;
    }
  }

  @if $style == 'zonerlistspan' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-xxxs;
    line-height: $line-height-xxxs;
    letter-spacing: $letter-spacing-s;
    text-decoration: none;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
    }

    @include breakpoint(xl) {
      font-size: $font-size-s;
      letter-spacing: $letter-spacing-s;
    }
  }}
