@import "variables.scss";
@import "mixins.scss";

@import "common.scss";
@import "svg.scss";

html {
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

html,
body {
  font-family: $font-sans;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

  &.noscroll{
    overflow: hidden;
  }
}

.overlay{
  @include z-index(overlayer);
  position: fixed;
  background-color:rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;

  &.open{
    display: block;
  }
}

.CookieConsent{
  .contentCookie{
    font-size: 14px;
    a{
      color: #ffffff !important;
    }
  }
  .buttonsCookie{
    display: flex;
    padding-top: 0px !important;
    justify-content: flex-end;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    button{

    }
    button.declineBtnCookie{
      background-color: #222 !important;
      border-radius: 4px !important;
      margin: 15px 15px 15px 5px;
    }
    button.acceptBtnCookie{
      color: #ffffff !important;
      background-color: #789325 !important;
      font-size: 13px;
      padding: 5px 10px 3px 10px !important;
      border-radius: 4px !important;
      margin: 15px 5px;
    }
  }
}
