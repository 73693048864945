@import "zindex.scss";

*, ::after, ::before {
    box-sizing: border-box;
}

button{
  background: transparent;
  border: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.row{
  margin: 0;
}

.row > *{
  padding: 0;
}

.col-2-9 {
  flex: 0 0 auto;
  width: calc(100% / 9 * 2);
}

.col-lg-2-9 {
  @include breakpoint(lg) {
    flex: 0 0 auto;
    width: calc(100% / 9 * 2);
  }
}

.col-3-9 {
  flex: 0 0 auto;
  width: calc(100% / 9 * 3);
}

.col-4-9 {
  flex: 0 0 auto;
  width: calc(100% / 9 * 4);
}

.col-lg-4-9{
  @include breakpoint(lg) {
    flex: 0 0 auto;
    width: calc(100% / 9 * 4);
  }
}

.col-7-9 {
  flex: 0 0 auto;
  width: calc(100% / 9 * 7);
}

@font-face {
   font-family: tStarPro;
   src: url(../fonts/TSTARPRO-Light.otf);
   font-weight: 300;
}

@font-face {
   font-family: tStarPro;
   src: url(../fonts/TSTARPRO-Regular.otf);
   font-weight: 400;
}

@font-face {
   font-family: tStarPro;
   src: url(../fonts/TSTARPRO-Bold.otf);
   font-weight: 700;
}

@font-face {
   font-family: tStarPro;
   src: url(../fonts/TSTARPRO-Headline.otf);
   font-weight: 800;
}

p {
  margin-bottom: 10px;
  color: $dark-grey;
}

a, a:focus, a:hover {

}

ul li {

}

table{
  margin-bottom: $size_m;
}

h1, h2, h3, h3, h4, h5, h6{
  margin: 0;
}

.square1{
  height: 33vw;
  background-color: $light-grey;
  display: flex;
  align-items: center;
  padding: $size_s $size_xs $size_xxs;
  justify-content: center;

  @include breakpoint(md) {
    padding: $size_s $size_s $size_xs;
  }

  @include breakpoint(lg) {
    padding: $size_s;
  }

  .projects &,
  .campus &{
    padding: $size_xs;
  }

  @include breakpoint(lg) {
    justify-content: flex-start;
  }

  h3{
    @include font-format('titles');

    .projects &,
    .campus &{
      @include font-format('titles_projects');
    }

    text-align: center;

    br{
      content: "";

      &::after{
        content: " ";
      }
    }

    @include breakpoint(lg) {
      text-align: left;

      br{
        display: block;
      }
    }
  }
}

.wsnw{
  white-space: nowrap
}

.topright{
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 50%;

  @include breakpoint(lg) {
    top: 30px;
    right: 30px;
  }
  @include breakpoint(xl) {
    max-width: 40%;

  }
}

.bottomleft{
  position: absolute;
  bottom: 15px;
  left: 15px;
  @include breakpoint(lg) {
    bottom: 30px;
    left: 30px;
  }
}

.bottomright{
  position: absolute;
  bottom: 15px;
  right: 15px;

  @include breakpoint(lg) {
    bottom: 30px;
    right: 30px;
  }
}

.plusContainer{
  @include z-index(plusBtn);
}

.plus{
  border: 0;
  padding: 0;
  width: 50px;
  height: 50px;

  .plusSvg{
    width: 50px;
  }

  .plussvg-1{stroke:$white; fill:none;}
  .plussvg-2{fill:$white;}

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.grey{
    .plussvg-1{stroke:$grey;}
    .plussvg-2{fill:$grey;}
  }
}

.play{
  border: 0;
  padding: 0;
  width: 50px;
  height: 50px;

  .playSvg{
    width: 50px;
  }

  .playPathSvg{
    fill: $white;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.grey{
    .playPathSvg{
      fill: $grey;
    }
  }
}

.link{
  border: 0;
  padding: 0;
  width: 50px;
  height: 50px;

  .linkSvg{
    width: 50px;
  }

  .linkPathSvg{
    fill: $white;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.grey{
    .linkPathSvg{
      fill: $grey;
    }
  }
}
