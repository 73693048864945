@import "variables.scss";
@import "mixins.scss";
@import "zindex.scss";

@keyframes flashing {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.campus{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;

  .campusSlide{
    height: 200px;
  }

  .square2, .square3, .square4{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 25vw;
    justify-content: center;
    overflow: hidden;
    background: $green;
    padding: 10px 0 0px;

    @include breakpoint(sm) {
      min-height: 0;
      height: 16vw;
    }

    @include breakpoint(md) {
      min-height: 0;
      height: 15vw;
    }

    @include breakpoint(lg) {
      height: 11vw;
    }

    &.light{
      //background: $green;
    }

    &.dark{
      background: $blue;
    }


    h5{
      @include font-format('numbersSubtitle');
      color: white;
      text-align: center;
    }

    h4{
      @include font-format('numbersTitle');
      color: white;
    }
  }


  .square5{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue;
    position:relative;
    height: 66vw;

    @include breakpoint(sm) {
      height: 33vw;
    }

    .swiper-button-prev, .swiper-button-next{
      color: $white;
    }

    .swiper{
      height: 100%;
      width: 100%;

      img{
        position: absolute;
        top: 0;
        left: 0;

        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper-button-prev, .swiper-button-next{
        color: $white;
      }
    }

    .overJace{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2); /* Green background with 30% opacity */
      z-index: 1;
    }

    .caption{
      z-index: 2;
      color: #fff;
      @include font-format('caption');
    }
  }

  .square6{
    padding: 30px 0px;
    height: 22vw;
    background: $violet;
    @include sfumatura(2);
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 100%;
    }
  }

  .square7{
    background: $white;
    position: relative;
    cursor: pointer;
    height: 66vw;

    @include breakpoint(sm) {
      height: 33vw;
    }

    @include breakpoint(lg) {
      height: 22vw;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
